
const usersApp = {
  state: {
    usersApp: []
  },
  mutations: {
    SET_USERS_APP (state, payload) {
      state.usersApp = payload
    }
  }

}

export default usersApp
