var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "q-input",
        _vm._g(
          _vm._b(
            {
              ref: "inputCustomCodar",
              class: _vm.classAtrrs,
              attrs: {
                "hide-bottom-space": "",
                "bottom-slots": "",
                label: _vm.label,
                dense: "",
                outlined: "",
                rounded: "",
                value: _vm.value,
                error: _vm.cError,
                "error-message": _vm.cErrorMessage,
              },
              nativeOn: {
                input: function ($event) {
                  return _vm.valorInputSeEmpty.apply(null, arguments)
                },
              },
              scopedSlots: _vm._u(
                [
                  _vm.iconElment.name
                    ? {
                        key: "before",
                        fn: function () {
                          return [
                            _c("q-icon", {
                              attrs: {
                                name: _vm.iconElment.name,
                                size: _vm.iconElment.size,
                                color: _vm.iconElment.color,
                              },
                            }),
                          ]
                        },
                        proxy: true,
                      }
                    : null,
                  _vm._l(_vm.$scopedSlots, function (_, slot) {
                    return {
                      key: slot,
                      fn: function (scope) {
                        return [_vm._t(slot, null, null, scope)]
                      },
                    }
                  }),
                ],
                null,
                true
              ),
            },
            "q-input",
            _vm.$attrs,
            false
          ),
          _vm.$listeners
        )
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }