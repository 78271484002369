var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "q-input",
        _vm._g(
          _vm._b(
            {
              staticClass: "full-width",
              class: _vm.classAtrrs,
              attrs: {
                "hide-bottom-space": "",
                outlined: "",
                "stack-label": "",
                type: "date",
                "bottom-slots": "",
                value: _vm.cValue,
                error: _vm.cError,
                "error-message": _vm.cErrorMessage,
                ruler: [(val) => _vm.dateIsValid(val) || "Data inválida!"],
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "append",
                    fn: function () {
                      return [
                        _c(
                          "q-icon",
                          {
                            staticClass: "cursor-pointer q-mr-sm",
                            attrs: { name: "event" },
                          },
                          [
                            _c(
                              "q-popup-proxy",
                              {
                                ref: "qDateProxy",
                                attrs: {
                                  "transition-show": "scale",
                                  "transition-hide": "scale",
                                },
                              },
                              [
                                _c("q-date", {
                                  attrs: {
                                    value: _vm.cQDate,
                                    "today-btn": "",
                                    mask: "DD/MM/YYYY",
                                  },
                                  on: { input: _vm.emitDate },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                    proxy: true,
                  },
                  _vm._l(_vm.$scopedSlots, function (_, slot) {
                    return {
                      key: slot,
                      fn: function (scope) {
                        return [_vm._t(slot, null, null, scope)]
                      },
                    }
                  }),
                ],
                null,
                true
              ),
            },
            "q-input",
            _vm.$attrs,
            false
          ),
          _vm.$listeners
        )
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }